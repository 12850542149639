:root {
  --main-theme: #1a1a1a; /* Dark text color */
  --accent-color: #007bff; /* Primary blue accent */
  --accent-hover: #0056b3;
  --background-light: #f7f7f7; /* Light background for content areas */
}

/* Reset & Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: var(--background-light);
  color: var(--main-theme);
  overflow-x: hidden;
}

.app-container {
  width: 100%;
}

/* Fixed Header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1000;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.logo {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--accent-color); /* Changed to accent color */
}

.nav-container {
  position: relative;
}

.nav-buttons {
  display: flex;
  gap: 8px;
}

.nav-buttons button {
  background: none;
  border: none;
  font-size: 1rem;
  color: var(--accent-color); /* Changed to accent color */
  cursor: pointer;
  padding: 8px 12px;
  transition: color 0.3s ease;
}

.nav-buttons button:hover {
  color: var(--accent-hover);
}

/* Burger Menu (hidden on desktop) */
.burger-menu {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--accent-color); /* Changed to accent color */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .nav-buttons {
    display: none;
    flex-direction: column;
    background: #fff;
    position: absolute;
    top: 60px;
    right: 5px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .nav-buttons.open {
    display: flex;
  }
  .burger-menu {
    display: block;
  }
}

/* Main Content (accounting for fixed header) */
.main-content {
  margin-top: 80px;
}

.hero-section {
  position: relative;
  height: 100vh;
  background: url("../public/chantelle2.jpg") no-repeat center center/cover;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .hero-section {
    background: url("../public/chantelle-hero-image.jpg") no-repeat center
      center/cover;
    background-attachment: scroll; /* Disable parallax on mobile for performance */
  }
}

.hero-overlay {
  text-align: center;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for readability */
  padding: 20px 40px;
  border-radius: 8px;
  color: #fff;
}

.hero-overlay h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-overlay p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.hero-overlay button {
  padding: 12px 24px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  background-color: var(--accent-color);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hero-overlay button:hover {
  background-color: var(--accent-hover);
}

/* Section Container Styles with Alternating Backgrounds */
.section-container {
  width: 90%;
  max-width: 800px;
  margin: 60px auto;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.9); /* Default background */
}

.section-container h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--accent-color);
  display: inline-block; /* Ensures the underline matches the text width */
  padding-bottom: 4px; /* Optional spacing between text and underline */
  border-bottom: 1px solid var(--accent-color);
}

/* Alternate background for even sections */
.main-content > .section-container:nth-of-type(even) {
  background-color: #f0f0f0;
}

.section-container h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--accent-color);
}

.section-container p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Lazy Load Section Animation with 0.5s Delay */
.lazy-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out 0.5s, transform 0.6s ease-out 0.5s;
}

.lazy-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.loading-placeholder,
.lazy-loading {
  padding: 40px;
  text-align: center;
  font-size: 1.2rem;
  color: var(--main-theme);
}

/* Carousel Container maintains a fixed aspect ratio */
.carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 800px; /* Max width for larger screens */
  aspect-ratio: 2 / 1; /* Maintains a 2:1 ratio (e.g., 800px by 400px) */
  margin: 0 auto;
  background-color: #f0f0f0; /* Optional: background color for letterboxing */
}

/* Each carousel item occupies the full container */
.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

/* The active slide is visible */
.carousel-item.active {
  opacity: 1;
  position: relative;
}

/* Ensure images scale to fit the container without cropping */
.carousel img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Switch from cover to contain */
}

@media (max-width: 600px) {
  .carousel {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .header.scrolled .nav-buttons {
    background: var(--accent-color);
  }
}

.header.scrolled {
  background: var(--accent-color);
  color: var(--background-light);
}

.header.scrolled .logo,
.header.scrolled .nav-buttons button,
.header.scrolled .burger-menu {
  color: var(--background-light);
}
